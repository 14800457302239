import React, { useContext } from "react";
import { Formik } from "formik";
import { CommodityItemInterface } from "@ifgengineering/hip-app-domain/src";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { CommoditySchema } from "../../constants/validation";
import { ButtonsContainer, Text, ErrorMessage } from "../../styled";
import { CommodityType } from "../../constants/commodity";
import { useDispatch } from "react-redux";
import LockIcon from "@assets/icons/lock.svg";
import {
  createCommodityAPI,
  updateCommodityAPI,
} from "../../actions/commodity";
import { updateCommoditiesStore, updateCommodityStore } from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  title: string;
  commodity: CommodityItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

interface CommodityFormField {
  description: string;
  monetaryValue: string;
}

interface CommodityFomTexts {
  fieldHeading: CommodityFormField;
  fieldDescription: CommodityFormField;
}

const FORM_LABELS: Record<CommodityType, CommodityFomTexts> = {
  [CommodityType.GOLD]: {
    fieldHeading: {
      description: "Description",
      monetaryValue: "Estimated Value",
    },
    fieldDescription: {
      description: "e.g My gold coins",
      monetaryValue: "How much you think your gold is worth",
    },
  },
  [CommodityType.SILVER]: {
    fieldHeading: {
      description: "Description",
      monetaryValue: "Silver Value",
    },
    fieldDescription: {
      description: "e.g my silver bracelet",
      monetaryValue: "How much you think your silver is worth",
    },
  },
  [CommodityType.OTHER]: {
    fieldHeading: {
      description: "Description",
      monetaryValue: "Item Value",
    },
    fieldDescription: {
      description: "e.g my ruby necklace",
      monetaryValue: "How much you think your item is worth",
    },
  },
};

const CommodityForm = ({
  title,
  commodity,
  previousPage,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const createAPI = async (values: CommodityItemInterface) => {
    const commodity = await dispatch(
      createCommodityAPI({
        email,
        commodities: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap.
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateCommoditiesStore(commodity));
  };

  const updateAPI = async (values: CommodityItemInterface) => {
    const commodity = await dispatch(
      updateCommodityAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap.
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateCommodityStore(commodity));
  };

  return (
    <Formik
      validationSchema={CommoditySchema}
      initialValues={commodity}
      onSubmit={(commodity) => {
        if (commodity.id) {
          updateAPI(commodity);
        } else {
          createAPI(commodity);
        }
        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors }) => (
        <FormikForm>
          <Text align="left">{title}</Text>
          <Input
            value={values.description || ""}
            heading={
              FORM_LABELS[commodity.commodityType].fieldHeading.description
            }
            name="description"
            description={
              FORM_LABELS[commodity.commodityType].fieldDescription.description
            }
            errorMessage={errors.description}
            onChange={(e) => setFieldValue("description", e.target.value)}
          />

          <MoneyInput
            heading={
              FORM_LABELS[commodity.commodityType].fieldHeading.monetaryValue
            }
            description={
              FORM_LABELS[commodity.commodityType].fieldDescription
                .monetaryValue
            }
            value={values.monetaryValue?.toString() || ""}
            currency={currencySymbol}
            onChange={(value) => setFieldValue("monetaryValue", Number(value))}
          />

          <div>
            {errors.monetaryValue && (
              <ErrorMessage>Monetary value must be provided</ErrorMessage>
            )}
          </div>
          <ButtonsContainer>
            <FormButton
              onClick={submitForm}
              type="submit"
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={isMobile ? previousPage : undefined}
              leftArrowLabel={isMobile ? "Back" : undefined}
            />
          </ButtonsContainer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default CommodityForm;

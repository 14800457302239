import React, { useContext } from "react";
import { Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { BusinessSchema } from "../../constants/validation";
import { ButtonsContainer, InnerWrapper, Text } from "../../styled";
import { BusinessItemInterface } from "@ifgengineering/hip-app-domain/src";
import { BusinessItemObject } from "../../constants/types";
import { useDispatch } from "react-redux";
import LockIcon from "@assets/icons/lock.svg";
import {
  createBusinessAPI,
  updateBusinessItemAPI,
} from "../../actions/businessAssets";
import { updateBusinessesStore, updateBusinessStore } from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  title: string;
  data: BusinessItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const BusinessAssetsForms = ({
  title,
  data,
  previousPage,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const createAPI = async (values: BusinessItemObject) => {
    const bankAccount = await dispatch(
      createBusinessAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateBusinessesStore(bankAccount));
  };

  const updateAPI = async (values: BusinessItemObject) => {
    const bankAccount = await dispatch(
      updateBusinessItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateBusinessStore(bankAccount));
  };

  return (
    <Formik
      validationSchema={BusinessSchema}
      initialValues={data}
      onSubmit={(business) => {
        if (business.id) {
          updateAPI(business);
        } else {
          createAPI(business);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <FormikForm>
            <Text align="left">{title}</Text>

            <Input
              value={values.businessName || ""}
              type="textarea"
              name="businessName"
              heading="Business description"
              description="e.g. IFG Limited Company Number 12345"
              errorMessage={errors.businessName}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setFieldValue("businessName", e.target.value)}
            />

            <MoneyInput
              heading="Total estimated value of business"
              value={values.totalValue.toString() || ""}
              onChange={(value) => setFieldValue("totalValue", Number(value))}
              currency={currencySymbol}
              errorMessage={
                touched.totalValue && errors.totalValue ? errors.totalValue : ""
              }
            />

            <ButtonsContainer>
              <FormButton
                onClick={submitForm}
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={isMobile ? previousPage : undefined}
                leftArrowLabel={isMobile ? "Back" : undefined}
              />
            </ButtonsContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default BusinessAssetsForms;

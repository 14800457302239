import React, { useContext, useState } from "react";
import { Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import LockIcon from "@assets/icons/lock.svg";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { DebtSchema } from "../../constants/validation";
import { ButtonsContainer, Text } from "../../styled";
import DropDown, {
  DropDownValue,
} from "../../../../legacyComponents/DropDown/DropDown.component";
import { DebtLabels, DebtType, DebtTypesList } from "../../constants/debt";
import { DebtItemInterface } from "@ifgengineering/hip-app-domain/src";
import { useDispatch } from "react-redux";
import { createDebtAPI, updateDebtItemAPI } from "../../actions/debt";
import { updateDebtItemStore, updateDebtStore } from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  title: string;
  debt: DebtItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const DebtForm = ({
  title,
  debt = {
    type: DebtType.MORTGAGE,
    value: 0,
  },
  previousPage,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const { currencySymbol } = useContext(CurrencyContext);
  const dispatch = useDispatch();
  const [selectedDropdownItem, setSelectedDropdownItem] =
    useState<DropDownValue | null>({
      value: debt.type,
      label: DebtLabels[debt.type],
    });

  const createAPI = async (values: DebtItemInterface) => {
    const debt = await dispatch(
      createDebtAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateDebtStore(debt));
  };

  const updateAPI = async (values: DebtItemInterface) => {
    const debt = await dispatch(
      updateDebtItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateDebtItemStore(debt));
  };

  return (
    <Formik
      validationSchema={DebtSchema}
      initialValues={debt}
      onSubmit={(debt) => {
        if (debt.id) {
          updateAPI(debt);
        } else {
          createAPI(debt);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <FormikForm>
            <Text align="left">{title}</Text>

            <DropDown
              options={DebtTypesList}
              onChange={(item) => {
                setFieldValue("type", item?.value);
                setSelectedDropdownItem(item);
              }}
              heading="Type of Debt"
              value={selectedDropdownItem}
            />

            <Input
              value={values.description?.toString() || ""}
              type="text"
              name="description"
              heading="Description"
              description="e.g. my Barclays credit card debt"
              errorMessage={errors.description}
              onChange={(e) => setFieldValue("description", e.target.value)}
            />

            <MoneyInput
              heading="Estimated Value"
              description="How much money you owe for this debt"
              value={values.value?.toString() || ""}
              onChange={(value) => setFieldValue("value", Number(value))}
              currency={currencySymbol}
              errorMessage={touched.value && errors.value ? errors.value : ""}
            />

            <ButtonsContainer>
              <FormButton
                onClick={submitForm}
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={isMobile ? previousPage : undefined}
                leftArrowLabel={isMobile ? "Back" : undefined}
              />
            </ButtonsContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default DebtForm;

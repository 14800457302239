import React, { useContext, useState } from "react";
import {
  InnerWrapper,
  Layout,
  Text,
  Description,
  CardsContainer,
} from "../../styled";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import CommodityForm from "./CommodityForm";
import { useDispatch, useSelector } from "react-redux";
import { getCommodities } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { CommodityItemInterface } from "@ifgengineering/hip-app-domain/src";
import LockIcon from "@assets/icons/lock.svg";
import { deleteCommodityStore } from "../../actions";
import AddItemCard from "@legacyComponents/AddItemCard/AddItemCard.component";
import Modal from "@legacyComponents/Modal/Modal.component";
import AccountCard from "@legacyComponents/AccountCard/AccountCard.component";
import { CommodityType } from "../../constants/commodity";
import { deleteCommodityAPI } from "../../actions/commodity";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyComodity: CommodityItemInterface = {
  commodityType: CommodityType.GOLD,
  monetaryValue: 0,
};

const Commodity: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCommodity, setSelectedCommodity] =
    useState<CommodityItemInterface>(emptyComodity);
  const commodities = useSelector(getCommodities);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const { currencySymbol } = useContext(CurrencyContext);
  const dispatch = useDispatch();

  const golds = commodities?.filter(
    (commodity) => commodity.commodityType === CommodityType.GOLD
  );
  const silvers = commodities?.filter(
    (commodity) => commodity.commodityType === CommodityType.SILVER
  );
  const items = commodities?.filter(
    (commodity) => commodity.commodityType === CommodityType.OTHER
  );

  const onClose = () => {
    setOpen(false);
    setSelectedCommodity(emptyComodity);
  };

  const deleteAPI = async (comodity: CommodityItemInterface) => {
    await dispatch(
      deleteCommodityAPI(comodity.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteCommodityStore(comodity));
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Commodity</Text>
              <Description>
                If you have any precious items such as gold or silver coins,
                jewellery or precious gems, you can add them here.
              </Description>
              {showTooltip && <Tooltip text={tooltipText.goldAndSilver} />}
            </div>
            <CardsContainer>
              <AddItemCard
                text="Add gold"
                onClick={() => {
                  setOpen(true);
                  setSelectedCommodity({
                    ...emptyComodity,
                    commodityType: CommodityType.GOLD,
                  });
                }}
              />
              {golds.map((gold) => (
                <AccountCard
                  name={gold.description || ""}
                  key={`${gold.id}-${gold.name}`}
                  currency={currencySymbol}
                  balance={gold.monetaryValue || gold.weight || 0}
                  onClickDelete={() => deleteAPI(gold)}
                  onClickEdit={() => {
                    setOpen(true);
                    setSelectedCommodity(gold);
                  }}
                />
              ))}
              <AddItemCard
                text="Add silver"
                onClick={() => {
                  setOpen(true);
                  setSelectedCommodity({
                    ...emptyComodity,
                    commodityType: CommodityType.SILVER,
                  });
                }}
              />

              {silvers.map((silver) => (
                <AccountCard
                  name={silver.description || ""}
                  key={`${silver.id}-${silver.name}`}
                  currency={currencySymbol}
                  balance={silver.monetaryValue || silver.weight || 0}
                  onClickDelete={() => deleteAPI(silver)}
                  onClickEdit={() => {
                    setOpen(true);
                    setSelectedCommodity(silver);
                  }}
                />
              ))}

              <AddItemCard
                text="Add precious item"
                onClick={() => {
                  setOpen(true);
                  setSelectedCommodity({
                    ...emptyComodity,
                    commodityType: CommodityType.OTHER,
                  });
                }}
              />

              {items.map((item) => (
                <AccountCard
                  name={item.description || ""}
                  key={`${item.id}-${item.name}`}
                  currency={currencySymbol}
                  balance={item.monetaryValue || item.weight || 0}
                  onClickDelete={() => deleteAPI(item)}
                  onClickEdit={() => {
                    setOpen(true);
                    setSelectedCommodity(item);
                  }}
                />
              ))}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <CommodityForm
          title={selectedCommodity?.commodityType.toLocaleLowerCase()}
          commodity={selectedCommodity}
          previousPage={previousPage}
          onSubmit={() => onClose()}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <CommodityForm
            title={selectedCommodity?.commodityType.toLocaleLowerCase()}
            commodity={selectedCommodity}
            previousPage={previousPage}
            onSubmit={() => onClose()}
          />
        </Modal>
      )}
    </>
  );
};

export default Commodity;

import React, { useContext, useState } from "react";
import {
  InnerWrapper,
  Layout,
  Text,
  Description,
  CardsContainer,
} from "../../styled";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@assets/icons/lock.svg";
import { getProperty } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import AddItemCard from "../../../../legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "../../../../legacyComponents/AccountCard/AccountCard.component";
import { deletePropertyStore } from "../../actions";
import { PropertyItemInterface } from "@ifgengineering/hip-app-domain/src";
import PropertyForm from "./propertyForm";
import Modal from "../../../../legacyComponents/Modal/Modal.component";
import { deletePropertyAPI } from "../../actions/property";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyState: PropertyItemInterface = {
  value: 0,
};

const property: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] =
    useState<PropertyItemInterface>(emptyState);

  const properties = useSelector(getProperty);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const { currencySymbol } = useContext(CurrencyContext);
  const dispatch = useDispatch();

  const onClose = () => {
    setOpen(false);
    setSelectedProperty(emptyState);
  };

  const deleteAPI = async (property: PropertyItemInterface) => {
    await dispatch(
      deletePropertyAPI(property.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deletePropertyStore(property));
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Property</Text>
              <Description>
                Enter the value of your properties you have including your
                primary residence, other property such as your second home and
                any buy-to-let properties.
              </Description>
              {showTooltip && <Tooltip text={tooltipText.property} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add property"
                onClick={() => {
                  setOpen(true);
                  setSelectedProperty(emptyState);
                }}
              />

              {properties.map((property: PropertyItemInterface) => {
                const { value, description = "" } = property;

                return (
                  <AccountCard
                    key={`${description}-${value}`}
                    name={description}
                    currency={currencySymbol}
                    balance={value}
                    onClickDelete={() => deleteAPI(property)}
                    onClickEdit={() => {
                      setOpen(true);
                      setSelectedProperty(property);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <PropertyForm
          title="Add property"
          data={selectedProperty}
          previousPage={previousPage}
          onSubmit={onClose}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <PropertyForm
            title="Add property"
            data={selectedProperty}
            previousPage={previousPage}
            onSubmit={onClose}
          />
        </Modal>
      )}
    </>
  );
};

export default property;

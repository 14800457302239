import * as Yup from "yup";

export const CashSchema = Yup.object().shape({
  cashInHand: Yup.number(),
  cashISA: Yup.number(),
});

export const BankAccountSchema = Yup.object().shape({
  bankAccountName: Yup.string().required("Bank Name is required"),
  cashInAccount: Yup.number().moreThan(
    0,
    "Cash in account must be greater than 0"
  ),
});

export const CommoditySchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
  monetaryValue: Yup.number().moreThan(
    0,
    "Estimated Value must be greater than 0"
  ),
});

export const DebtSchema = Yup.object().shape({
  value: Yup.number().moreThan(0, "Estimated Value must be greater than 0"),
  description: Yup.string().required("Description is required"),
});

export const ChildTrustFundSchema = Yup.object().shape({
  totalValue: Yup.number().moreThan(0, "Value must be greater than 0"),
  description: Yup.string().required("Description is required"),
});

export const FundSchema = Yup.object().shape({
  totalValue: Yup.number().moreThan(0, "Value must be greater than 0"),
  fundName: Yup.string().required("Name of Fund is required"),
});

export const ShareSchema = Yup.object().shape({
  shareName: Yup.string().required("Name of Share is required"),
  totalValue: Yup.number().moreThan(
    0,
    "Estimated Total Value of Shares must be greater than 0"
  ),
});

export const AgricultureAssetsSchema = Yup.object().shape({
  value: Yup.number().moreThan(0, "Value must be greater than 0"),
  description: Yup.string().required("Description is required"),
});

export const CryptoSchema = Yup.object().shape({
  totalValue: Yup.number().moreThan(0, "Value must be greater than 0"),
  coinName: Yup.string().required("Name of Coin is required"),
  numCoins: Yup.number().nullable(),
});

export const StartupSchema = Yup.object().shape({
  value: Yup.number().moreThan(0, "Value must be greater than 0"),
  companyName: Yup.string().required("Company name is required"),
});

export const AlternativeInvestmentsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  value: Yup.number().moreThan(0, "Value must be greater than 0"),
  description: Yup.string(),
});

export const OwedAssetItemSchema = Yup.object().shape({
  value: Yup.number().moreThan(0, "Overall value must be greater than 0"),
  description: Yup.string().required("Description is required"),
});

export const BusinessSchema = Yup.object().shape({
  totalValue: Yup.number().moreThan(0, "Total Value must be greater than 0"),
  businessName: Yup.string().required("Business Name is required"),
});

export const PensionSchema = Yup.object().shape({
  totalValue: Yup.number().moreThan(0, "Value must be greater than 0"),
  description: Yup.string().required("Description is required"),
});

export const PropertySchema = Yup.object().shape({
  value: Yup.number().moreThan(0, "Value must be greater than 0"),
  description: Yup.string().required("Description is required"),
});

import React, { useContext, useState } from "react";
import {
  InnerWrapper,
  Layout,
  Text,
  Description,
  CardsContainer,
} from "../../styled";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@assets/icons/lock.svg";
import { getOwedAssets } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import AddItemCard from "../../../../legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "../../../../legacyComponents/AccountCard/AccountCard.component";
import { deleteOwedAssetStore } from "../../actions";
import Modal from "../../../../legacyComponents/Modal/Modal.component";
import OwedAssetsForm from "./OwedAssetsForm";
import { OwedAssetItemInterface } from "@ifgengineering/hip-app-domain/src";
import { OwedAssetItemObject } from "../../constants/types";
import { deleteOwedAssetAPI } from "../../actions/owedAssets";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyState: OwedAssetItemInterface = {
  value: 0,
};

const OwedAssets: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedTrust, setSelectedTurst] =
    useState<OwedAssetItemInterface>(emptyState);
  const { currencySymbol } = useContext(CurrencyContext);
  const owedAssetList = useSelector(getOwedAssets);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();

  const deleteAPI = async (value: OwedAssetItemObject) => {
    await dispatch(
      deleteOwedAssetAPI(value.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteOwedAssetStore(value));
  };

  const onClose = () => {
    setOpen(false);
    setSelectedTurst(emptyState);
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Assets owed to you</Text>
              <Description>
                If you have lent anybody or instituition money or other asset
                that they now owe you, you can add it here.
              </Description>
              {showTooltip && <Tooltip text={tooltipText.owedAssets} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add asset owed to you"
                onClick={() => {
                  setOpen(true);
                  setSelectedTurst({
                    value: 0,
                  });
                }}
              />

              {owedAssetList.map((owedAsset) => {
                const { value, description = "" } = owedAsset;

                return (
                  <AccountCard
                    key={`${description}-${value}`}
                    name={description}
                    currency={currencySymbol}
                    balance={value}
                    onClickDelete={() => deleteAPI(owedAsset)}
                    onClickEdit={() => {
                      setOpen(true);
                      setSelectedTurst(owedAsset);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <OwedAssetsForm
          title="Add asset owed"
          data={selectedTrust}
          previousPage={previousPage}
          onSubmit={onClose}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <OwedAssetsForm
            title="Add asset owed"
            data={selectedTrust}
            previousPage={previousPage}
            onSubmit={onClose}
          />
        </Modal>
      )}
    </>
  );
};

export default OwedAssets;

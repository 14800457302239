import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { FundItemInterface } from "@ifgengineering/hip-app-domain/src";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_FUND_API,
  DELETE_FUND_API,
  UPDATE_FUND_API,
} from "../constants/actionTypes";
import { FundItemObject, FundObject } from "../constants/types";

export const createFundAPI = createAsyncThunk<
  FundObject,
  {
    email: string;
    data: FundItemObject[];
  }
>(CREATE_FUND_API, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: [CreateFundItemInput!]!, $email: String!) {
          createFundAsset(data: $data, email: $email) {
            id
            totalValue
            fundsList {
              id
              totalValue
              fundName
              fundTicker
              numUnits
            }
          }
        }
      `,
      variables: {
        email,
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createFundAsset;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createFundAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateFundItemAPI = createAsyncThunk<
  FundItemObject,
  FundItemInterface
>(UPDATE_FUND_API, async (data) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdateFundItemInput!) {
          updateFundItem(data: $data) {
            id
            totalValue
            fundName
            fundTicker
            numUnits
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.updateFundItem;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateFundItemAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteFundAPI = createAsyncThunk<string, number>(
  DELETE_FUND_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteFundItem(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.deleteFundItem;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteFundAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);

import { gql } from "@apollo/client";
import { PersonalFinanceInterface } from "@ifgengineering/hip-app-domain/src";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../../graphql";
import { toast } from "react-toastify";
import { store } from "../../state/index";
import { logoutAction } from "../../../components/Login/actions";
import { logException } from "@helpers/logException";

export type PersonalFinanceInput = {
  monthlyIncome?: number;
  monthlyExpenditure?: number;
  investmentFrequency?: string;
  savingGoals?: string[];
  hasIslamicWill?: boolean;
};

export const FETCH_PERSONAL_FINANCE = "FETCH_PERSONAL_FINANCE";
export const UPDATE_PERSONAL_FINANCE = "UPDATE_PERSONAL_FINANCE";

export const fetchOrCreatePersonalFinanceAPI = createAsyncThunk<
  PersonalFinanceInterface,
  PersonalFinanceInput
>(FETCH_PERSONAL_FINANCE, async (data) => {
  try {
    const response = await client.query<PersonalFinanceInterface>({
      fetchPolicy: "no-cache",
      query: gql`
        query ($data: PersonalFinanceInput!) {
          findOrCreatePersonalFinanceData(data: $data) {
            id
            monthlyIncome
            monthlyExpenditure
            investmentFrequency
            savingGoals
            hasIslamicWill
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.findOrCreatePersonalFinanceData;
  } catch (e: any) {
    if (e.message.match(/Unauthorized/gi)) {
      store.dispatch(logoutAction());
    } else {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "fetchOrCreatePersonalFinanceAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
});

export const updatePersonalFinanceAPI = createAsyncThunk<
  PersonalFinanceInterface,
  PersonalFinanceInput
>(UPDATE_PERSONAL_FINANCE, async (data) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: PersonalFinanceInput!) {
          updatePersonalFinanceData(data: $data) {
            id
            monthlyIncome
            monthlyExpenditure
            investmentFrequency
            savingGoals
            hasIslamicWill
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.updatePersonalFinanceData;
  } catch (e: any) {
    if (
      e.networkError.result.errors.some(
        ({ statusCode }: { statusCode: number }) => statusCode === 401
      )
    ) {
      store.dispatch(logoutAction());
    } else {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "updatePersonalFinanceAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
});

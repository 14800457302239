import { WillInterface } from "@ifgengineering/hip-app-domain/src";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "../state";
import { fetchWillsAPI } from "../state/wills/wills.actions";
import { useUserData } from "./useUserData";

export const useWillsData = () => {
  const [loading, setLoading] = useState(true);
  const [wills, setWills] = useState<WillInterface | null>(null);
  const dispatch = useDispatch<typeof store.dispatch>();
  const { user } = useUserData();

  useEffect(() => {
    if (user?.email) {
      const loadWills = async () => {
        const wills = await dispatch(fetchWillsAPI(user?.email)).unwrap();
        setWills(wills);
        setLoading(false);
      };

      loadWills();
    }
  }, [user?.email]);

  return { wills, loading };
};

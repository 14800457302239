import {
  PortfolioDataInterface,
  CommodityItemInterface,
  ChildTrustFundItemInterface,
  DebtItemInterface,
  BankAccountItemInterface,
  FundItemInterface,
  ShareTickerInterface,
  AgricultureItemInterface,
  CryptoItemInterface,
  StartupItemInterface,
  DetailedAssetInterface,
  OwedAssetItemInterface,
  BusinessItemInterface,
  PensionItemInterface,
  PropertyItemInterface,
} from "@ifgengineering/hip-app-domain/src";
import { ASSETS, ASSETS_LIST_VALUES, PortfolioList, Selector } from "./types";
import { CashObject } from "./constants/types";

export const getPortfolioData: Selector<PortfolioDataInterface> = (state) =>
  state.portfolioData;

export const getPortfolioCurrency: Selector<
  PortfolioDataInterface["currency"]
> = (state) => state.portfolioData?.currency;

export const getAssetsWithValue = (
  portfolioData: PortfolioDataInterface
): ASSETS[] => {
  const { id, ...portfolio } = portfolioData;
  const selectedKeys: ASSETS[] = [];

  if (portfolio.cash?.cashInHand || portfolio.cash?.cashISA) {
    selectedKeys.push(ASSETS.cash);
  }

  Object.entries(portfolio).forEach(([key, value]) => {
    const listItem = ASSETS_LIST_VALUES[key as keyof PortfolioList];

    if (value[listItem]?.length) {
      selectedKeys.push(key as ASSETS);
    }
  });

  return selectedKeys;
};

export const getSelectedPortfolioData: Selector<ASSETS[]> = (state) =>
  getAssetsWithValue(state.portfolioData);

export const getEstimatedEstateValue: Selector<
  PortfolioDataInterface["estimatedEstateValue"]
> = (state) => state.portfolioData.estimatedEstateValue;

export const getCashAssets: Selector<CashObject> = (state) =>
  state.portfolioData.cash;

export const getFullPortfolioData: Selector<PortfolioDataInterface> = (state) =>
  state.portfolioData;

export const getBankAccounts: Selector<BankAccountItemInterface[]> = (state) =>
  state.portfolioData.bankAccounts?.bankAccountList || [];

export const getCommodities: Selector<CommodityItemInterface[]> = (
  state
): CommodityItemInterface[] =>
  state.portfolioData.commodities?.commoditiesList || [];

export const getDebts: Selector<DebtItemInterface[]> = (state) =>
  state.portfolioData.debts?.debt || [];

export const getChildTrustFundList: Selector<ChildTrustFundItemInterface[]> = (
  state
) => state.portfolioData.childTrustFunds?.childTrustFundList || [];

export const getFunds: Selector<FundItemInterface[]> = (state) =>
  state.portfolioData.funds?.fundsList || [];

export const getShares: Selector<ShareTickerInterface[]> = (state) =>
  state.portfolioData.stocks?.shareList || [];

export const getAgricultures: Selector<AgricultureItemInterface[]> = (state) =>
  state.portfolioData.agricultureAssets?.agricultureList || [];

export const getCrypto: Selector<CryptoItemInterface[]> = (state) =>
  state.portfolioData.crypto?.cryptoList || [];

export const getStartups: Selector<StartupItemInterface[]> = (state) =>
  state.portfolioData.startups?.startupInvestments || [];

export const getAlternativeInvestments: Selector<DetailedAssetInterface[]> = (
  state
) => state.portfolioData.alternativeInvestments?.alternativeAssetList || [];

export const getOwedAssets: Selector<OwedAssetItemInterface[]> = (state) =>
  state.portfolioData.owedAssets?.owedAssetList || [];

export const getBusinesses: Selector<BusinessItemInterface[]> = (state) =>
  state.portfolioData.businessAssets?.businessList || [];

export const getPensions: Selector<PensionItemInterface[]> = (state) =>
  state.portfolioData.pensions?.pensionList || [];

export const getProperty: Selector<PropertyItemInterface[]> = (state) =>
  state.portfolioData.properties?.propertyList || [];

export const hasSavedAssetsSelector: Selector<boolean> = (state) => {
  const portfolio = state.portfolioData;
  const keys = portfolio && Object.keys(portfolio);

  return keys.some(
    (key) => portfolio[key]?.totalCashValue || portfolio[key]?.totalValue
  );
};

import { fetchUserInfoAPI, updateUserInfo } from "@components/Login/actions";
import {
  fetchProductsAPI,
  updateProducts,
} from "../../state/discover/discover.actions";
import {
  fetchShortlistItemsAPI,
  addItemsToShortlistStore,
} from "../../state/shortlist/shortlist.actions";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../state";
import { fetchPortfolioAPI } from "@components/PortfolioData/actions/portfolio";
import { updatePortfolio } from "@components/PortfolioData/actions";
import { CurrencyContext } from "@components/CurrencyContext";
import { Currency } from "@ifgengineering/hip-app-domain/src";
import {
  getUserEmail,
  neverLoadedAuthSelector,
} from "@components/Login/selectors";

const PreFetchData: React.FC = ({ children }) => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { setCurrency } = useContext(CurrencyContext);
  const neverLoadedAuth = useSelector(neverLoadedAuthSelector);
  const userEmail = useSelector(getUserEmail);

  async function userInfo() {
    const { data: user } = await dispatch(fetchUserInfoAPI()).unwrap();

    if (user) {
      dispatch(updateUserInfo(user));
      return user;
    }
  }

  const loadProducts = async () => {
    const products = await dispatch(fetchProductsAPI()).unwrap();

    if (products) {
      dispatch(updateProducts(products));
    }
  };

  const loadShortlist = async () => {
    const { data } = await dispatch(fetchShortlistItemsAPI()).unwrap();

    if (data) {
      dispatch(addItemsToShortlistStore(data));
    }
  };

  const portfolio = async (userEmail: string) => {
    const { data } = await dispatch(fetchPortfolioAPI(userEmail)).unwrap();

    if (data) {
      setCurrency(data.currency as Currency);
      dispatch(updatePortfolio(data));
    }
  };

  async function loadData() {
    if (neverLoadedAuth) {
      await userInfo();
    }

    if (!userEmail) {
      return;
    }

    portfolio(userEmail);

    await loadProducts();
    await loadShortlist();
  }

  useEffect(() => {
    loadData();
  }, [neverLoadedAuth]);

  return <>{children}</>;
};

export default PreFetchData;

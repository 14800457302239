import React, { useContext, useState } from "react";
import {
  CardsContainer,
  Description,
  InnerWrapper,
  Layout,
  Text,
} from "../../styled";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { BankAccountItemInterface } from "@ifgengineering/hip-app-domain/src";
import LockIcon from "@assets/icons/lock.svg";
import AddItemCard from "@legacyComponents/AddItemCard/AddItemCard.component";
import Modal from "@legacyComponents/Modal/Modal.component";
import AccountForm from "./accountForm";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { getBankAccounts } from "../../selectors";
import AccountCard from "@legacyComponents/AccountCard/AccountCard.component";
import { deleteBankAccountItemStore } from "../../actions";
import { deleteBankAccountAPI } from "../../actions/bankAccount";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const BankAccount: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const bankAccountsList = useSelector(getBankAccounts);
  const { currencySymbol } = useContext(CurrencyContext);
  const [selectedBank, setSelectedBank] =
    useState<BankAccountItemInterface | null>(null);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();

  const deleteAPI = async (value: BankAccountItemInterface) => {
    await dispatch(
      deleteBankAccountAPI(value.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteBankAccountItemStore(value));
  };

  const renderAccounts = () =>
    bankAccountsList?.map((acc, index) => (
      <AccountCard
        key={index}
        name={acc.bankAccountName || ""}
        currency={currencySymbol}
        balance={acc.cashInAccount || 0}
        onClickDelete={() => deleteAPI(acc)}
        onClickEdit={() => {
          setSelectedBank(acc);
          setOpen(true);
        }}
      />
    ));

  const onClose = () => {
    setOpen(false);
    setSelectedBank(null);
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Bank Accounts</Text>
              <Description>
                List out any bank accounts you may have. E.g. Barclays
              </Description>
              {showTooltip && <Tooltip text={tooltipText.bankAccounts} />}
            </div>
            <CardsContainer>
              <AddItemCard
                text="Add Bank Account"
                onClick={() => setOpen(true)}
              />
              {renderAccounts()}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <AccountForm selected={selectedBank} closeAddMode={onClose} />
      ) : (
        <Modal onClose={onClose} open={open}>
          <AccountForm selected={selectedBank} closeAddMode={onClose} />
        </Modal>
      )}
    </>
  );
};

export default BankAccount;

import React, { useContext, useState } from "react";
import LockIcon from "@assets/icons/lock.svg";
import { InnerWrapper, Layout, Text, CardsContainer } from "../../styled";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { getFunds, getShares } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import AddItemCard from "../../../../legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "../../../../legacyComponents/AccountCard/AccountCard.component";
import { deleteFundStore, deleteShareItemStore } from "../../actions";
import Modal from "../../../../legacyComponents/Modal/Modal.component";
import FundForm from "./fundForm";
import ShareForm from "./shareForm";
import {
  FundItemInterface,
  ShareTickerInterface,
} from "@ifgengineering/hip-app-domain/src";
import { deleteShareAPI } from "../../actions/share";
import { deleteFundAPI } from "../../actions/fund";
import { Description } from "../../styled";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

enum FormTypes {
  FUND = "FUND",
  SHARE = "SHARE",
}

const emptyFund: FundItemInterface = {
  totalValue: 0,
  fundName: "",
};

const emptyShare: ShareTickerInterface = {
  shareName: "",
  totalValue: 0,
  market: "",
  nameTicker: "",
};

const StocksAndFunds: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormTypes>(FormTypes.FUND);
  const [selectedFund, setSelectedFund] =
    useState<FundItemInterface>(emptyFund);
  const [selectedShare, setSelectedShare] =
    useState<ShareTickerInterface>(emptyShare);

  const funds = useSelector(getFunds);
  const shares = useSelector(getShares);
  const { currencySymbol } = useContext(CurrencyContext);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();

  const onClose = () => {
    setOpen(false);
    setSelectedFund(emptyFund);
    setSelectedShare(emptyShare);
  };

  const deleteShare = async (share: ShareTickerInterface) => {
    await dispatch(
      deleteShareAPI(share.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteShareItemStore(share));
  };

  const deleteFund = async (fund: FundItemInterface) => {
    await dispatch(
      deleteFundAPI(fund.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteFundStore(fund));
  };

  const formComponent =
    formType === FormTypes.FUND ? (
      <>
        <FundForm
          data={selectedFund}
          onSubmit={onClose}
          previousPage={previousPage}
        />
      </>
    ) : (
      <>
        <ShareForm
          data={selectedShare}
          onSubmit={onClose}
          previousPage={previousPage}
        />
      </>
    );

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Shares & Funds</Text>
              <Description>
                Add any investments you have made in the public stock markets
              </Description>
              {showTooltip && <Tooltip text={tooltipText.sharesAndFunds} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add share"
                onClick={() => {
                  setOpen(true);
                  setFormType(FormTypes.SHARE);
                  setSelectedShare(emptyShare);
                }}
              />

              {shares.map((share) => {
                const { shareName = "", totalValue = 0 } = share;

                return (
                  <AccountCard
                    key={`${shareName}-${totalValue}`}
                    name={shareName}
                    currency={currencySymbol}
                    balance={totalValue}
                    onClickDelete={() => deleteShare(share)}
                    onClickEdit={() => {
                      setOpen(true);
                      setFormType(FormTypes.SHARE);
                      setSelectedShare(share);
                    }}
                  />
                );
              })}
            </CardsContainer>
            <CardsContainer>
              <AddItemCard
                text="Add fund"
                onClick={() => {
                  setOpen(true);
                  setFormType(FormTypes.FUND);
                  setSelectedFund(emptyFund);
                }}
              />

              {funds.map((fund) => {
                const { fundName = "", totalValue = 0 } = fund;

                return (
                  <AccountCard
                    key={`${fundName}-${totalValue}`}
                    name={fundName}
                    currency={currencySymbol}
                    balance={totalValue}
                    onClickDelete={() => deleteFund(fund)}
                    onClickEdit={() => {
                      setOpen(true);
                      setFormType(FormTypes.FUND);
                      setSelectedFund(fund);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        formComponent
      ) : (
        <Modal onClose={onClose} open={open}>
          {formComponent}
        </Modal>
      )}
    </>
  );
};

export default StocksAndFunds;

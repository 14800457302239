import React, { useContext, useState } from "react";
import {
  InnerWrapper,
  Layout,
  Text,
  Description,
  CardsContainer,
} from "../../styled";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { getBusinesses } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import AddItemCard from "@legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "@legacyComponents/AccountCard/AccountCard.component";
import { deleteBusinessStore } from "../../actions";
import Modal from "@legacyComponents/Modal/Modal.component";
import BusinessAssetsForms from "./BusinessAssetsForms";
import { BusinessItemInterface } from "@ifgengineering/hip-app-domain/src";
import { BusinessItemObject } from "../../constants/types";
import { deleteBusinessAPI } from "../../actions/businessAssets";
import Tooltip from "@components/Tooltip";
import LockIcon from "@assets/icons/lock.svg";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyState: BusinessItemInterface = {
  totalValue: 0,
};

const BusinessAssets: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedBusiness, setSelectedBusiness] =
    useState<BusinessItemInterface>(emptyState);

  const businessList = useSelector(getBusinesses);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const deleteAPI = async (value: BusinessItemObject) => {
    await dispatch(
      deleteBusinessAPI(value.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteBusinessStore(value));
  };

  const onClose = () => {
    setOpen(false);
    setSelectedBusiness(emptyState);
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Business Assets</Text>
              <Description>
                Business assets include Cash, Recievables (money owed to the
                business) and inventory. Inventory should be valued at the
                retail price.
              </Description>
              {showTooltip && <Tooltip text={tooltipText.businessAssets} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add business asset"
                onClick={() => {
                  setOpen(true);
                  setSelectedBusiness({
                    totalValue: 0,
                  });
                }}
              />

              {businessList.map((business) => {
                const { totalValue, businessName = "" } = business;

                return (
                  <AccountCard
                    key={`${businessName}-${totalValue}`}
                    name={businessName}
                    currency={currencySymbol}
                    balance={totalValue}
                    onClickDelete={() => deleteAPI(business)}
                    onClickEdit={() => {
                      setOpen(true);
                      setSelectedBusiness(business);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <BusinessAssetsForms
          title="Add business asset"
          data={selectedBusiness}
          previousPage={previousPage}
          onSubmit={onClose}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <BusinessAssetsForms
            title="Add business asset"
            data={selectedBusiness}
            previousPage={previousPage}
            onSubmit={onClose}
          />
        </Modal>
      )}
    </>
  );
};

export default BusinessAssets;

import { CompareProductInterface } from "@ifgengineering/hip-app-domain/src";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "../state";
import { fetchProductsAPI } from "../state/discover/discover.actions";

export const useProductsData = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<CompareProductInterface[]>([]);
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    const loadProducts = async () => {
      const products = await dispatch(fetchProductsAPI()).unwrap();
      if (products) {
        setProducts(products);
      }
      setLoading(false);
    };

    loadProducts();
  }, []);

  return { products, loading };
};

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrencyDescription,
  InnerWrapper,
  Layout,
  Spacer,
} from "../../styled";
import { Currency } from "@ifgengineering/hip-app-domain/src";
import { TextType } from "@ifgengineering/component-library";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import LockIcon from "@assets/icons/lock.svg";
import DropDown, {
  DropDownValue,
} from "@legacyComponents/DropDown/DropDown.component";
import Tooltip from "@components/Tooltip";
import { getUserEmail } from "@components/Login/selectors";
import { updateCurrencyApi } from "@components/PortfolioData/actions/currency";
import { getPortfolioCurrency } from "@components/PortfolioData/selectors";
import toolTipText from "@components/PortfolioData/constants/tooltipText";
import store from "@Wills/store";
import { CurrencyContext } from "@components/CurrencyContext";
import { ContextCurrencies } from "@components/CurrencyContext/constants";
import useAnalytics from "@helpers/useAnalytics";

interface EstimatedEstateValueProps {
  showTooltip?: boolean;
  onClickContinue?: () => void;
  onClickBack?: () => void;
}

const CurrencyPage: React.FC<EstimatedEstateValueProps> = ({
  showTooltip,
  onClickContinue,
}) => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const portfolioCurrency = useSelector(getPortfolioCurrency);
  const { setCurrency } = useContext(CurrencyContext);
  const [value, setValue] = useState<DropDownValue<Currency>>();
  const firePageViewAnalytics = useAnalytics("pageView");

  useEffect(() => {
    !!portfolioCurrency &&
      setValue({
        value: portfolioCurrency as Currency,
        label: portfolioCurrency.toUpperCase(),
      });
  }, [portfolioCurrency]);

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({
        eventName,
      });
    };
    firePageView(`all:currency_pageViewed`);
  }, []);

  const currencyOptions = Object.keys(ContextCurrencies).map((cur) => ({
    value: cur,
    label: cur.toUpperCase(),
  }));
  const userEmail = useSelector(getUserEmail);
  const submitData = async () => {
    const response = await dispatch(
      updateCurrencyApi({
        email: userEmail,
        data: { currency: value?.value },
      })
    );

    if (value) {
      setCurrency(value?.value);
    }

    onClickContinue && response?.payload && onClickContinue();
  };

  return (
    <Layout>
      <InnerWrapper>
        <Spacer />
        <CurrencyDescription type={TextType.P16} color={"SLATE800"}>
          Welcome! The goal of this section is to capture your assets.
        </CurrencyDescription>
        <CurrencyDescription type={TextType.P16} color={"SLATE800"}>
          {`Once you have inputted your asset data it will be automatically
           populated and updated across our zakat calculator, track feature and
           even your will (provided it hasn't been submitted) to save you time
           and hassle!`}
        </CurrencyDescription>
        {showTooltip && (
          <Tooltip openOnMount text={toolTipText.currency} place={"top"} />
        )}
        <DropDown
          testId="currency-dropdown"
          placeholder="Select currency"
          onChange={setValue}
          options={currencyOptions}
          value={value}
          heading="Currency"
          description="Please select a currency"
        />
      </InnerWrapper>
      <InnerWrapper>
        <FormButton
          testId="currency-continue-button"
          text="Save & Continue"
          disclaimerIcon={<LockIcon />}
          disclaimerText={
            <div>
              We take your{" "}
              <a
                href="https://www.islamicfinanceguru.com/security-policy"
                target="_blank"
                rel="noreferrer"
              >
                privacy seriously
              </a>
              . Our institutional-grade security ensures your data is
              confidential.
            </div>
          }
          onClick={() => {
            submitData();
            onClickContinue && onClickContinue();
          }}
        />
      </InnerWrapper>
    </Layout>
  );
};

export default CurrencyPage;

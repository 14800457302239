import React, { useContext, useState } from "react";
import { InnerWrapper, Layout, Text, CardsContainer } from "../../styled";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { PageType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { getCrypto } from "../../selectors";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import AddItemCard from "../../../../legacyComponents/AddItemCard/AddItemCard.component";
import AccountCard from "../../../../legacyComponents/AccountCard/AccountCard.component";
import { deleteCryptoStore } from "../../actions";
import Modal from "../../../../legacyComponents/Modal/Modal.component";
import CryptoForm from "./CryptoForm";
import { CryptoItemInterface } from "@ifgengineering/hip-app-domain/src";
import LockIcon from "@assets/icons/lock.svg";
import { deleteCryptoAPI } from "../../actions/crypto";
import { Description } from "../../styled";
import Tooltip from "@components/Tooltip";
import tooltipText from "@components/PortfolioData/constants/tooltipText";
import { CurrencyContext } from "@components/CurrencyContext";

const emptyState: CryptoItemInterface = {
  totalValue: 0,
  numCoins: 0,
};

const Crypto: React.FC<PageType> = ({
  nextPage,
  previousPage,
  showTooltip,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCrypto, setSelectedCrypto] =
    useState<CryptoItemInterface>(emptyState);

  const cryptoList = useSelector(getCrypto);
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const dispatch = useDispatch();
  const { currencySymbol } = useContext(CurrencyContext);

  const onClose = () => {
    setOpen(false);
    setSelectedCrypto(emptyState);
  };

  const deleteAPI = async (debt: CryptoItemInterface) => {
    await dispatch(
      deleteCryptoAPI(debt.id as number)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(deleteCryptoStore(debt));
  };

  return (
    <>
      {(!open || !isMobile) && (
        <Layout>
          <InnerWrapper>
            <div>
              <Text align="left">Crypto</Text>
              <Description>
                These are any cryptocurrency holdings you have. E.g. Bitcoin
              </Description>
              {showTooltip && <Tooltip text={tooltipText.crypto} />}
            </div>

            <CardsContainer>
              <AddItemCard
                text="Add Crypto"
                onClick={() => {
                  setOpen(true);
                  setSelectedCrypto({
                    totalValue: 0,
                  });
                }}
              />

              {cryptoList.map((crypto) => {
                const { totalValue = 0, coinName = "" } = crypto;

                return (
                  <AccountCard
                    key={`${coinName}-${totalValue}`}
                    name={coinName}
                    currency={currencySymbol}
                    balance={totalValue}
                    onClickDelete={() => deleteAPI(crypto)}
                    onClickEdit={() => {
                      setOpen(true);
                      setSelectedCrypto(crypto);
                    }}
                  />
                );
              })}
            </CardsContainer>
          </InnerWrapper>
          <InnerWrapper>
            <FormButton
              onClick={nextPage}
              text="Save & Continue"
              disclaimerIcon={<LockIcon />}
              disclaimerText={
                <div>
                  We take your{" "}
                  <a
                    href="https://www.islamicfinanceguru.com/security-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy seriously
                  </a>
                  . Our institutional-grade security ensures your data is
                  confidential.
                </div>
              }
              leftArrowOnClick={previousPage}
              leftArrowLabel="Back"
            />
          </InnerWrapper>
        </Layout>
      )}
      {isMobile && open ? (
        <CryptoForm
          title="Add crypto"
          data={selectedCrypto}
          previousPage={previousPage}
          onSubmit={onClose}
        />
      ) : (
        <Modal onClose={onClose} open={open}>
          <CryptoForm
            title="Add crypto"
            data={selectedCrypto}
            previousPage={previousPage}
            onSubmit={onClose}
          />
        </Modal>
      )}
    </>
  );
};

export default Crypto;

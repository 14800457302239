import React, { useContext } from "react";
import { Formik } from "formik";
import LockIcon from "@assets/icons/lock.svg";
import { useMediaQuery } from "react-responsive";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { FundSchema } from "../../constants/validation";
import { ButtonsContainer, InnerWrapper, Text } from "../../styled";
import { FundItemInterface } from "@ifgengineering/hip-app-domain/src";
import { useDispatch } from "react-redux";
import { createFundAPI, updateFundItemAPI } from "../../actions/fund";
import { updateFundsStore, updateFundStore } from "../../actions";
import { AuthContext } from "../..";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  data: FundItemInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const FundForm = ({ data, previousPage, onSubmit }: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const { currencySymbol } = useContext(CurrencyContext);
  const dispatch = useDispatch();

  const createAPI = async (values: FundItemInterface) => {
    const fund = await dispatch(
      createFundAPI({
        email,
        data: [values],
      })
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateFundsStore(fund));
  };

  const updateAPI = async (values: FundItemInterface) => {
    const fund = await dispatch(
      updateFundItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap. 
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateFundStore(fund));
  };

  return (
    <Formik
      validationSchema={FundSchema}
      initialValues={data}
      onSubmit={(fund) => {
        if (fund.id) {
          updateAPI(fund);
        } else {
          createAPI(fund);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <FormikForm>
            <Text align="left">Add fund</Text>

            <Input
              value={values.fundName || ""}
              type="text"
              name="fundName"
              heading="Name of fund"
              description="Name of the fund you have invested into, e.g. S&P 500"
              errorMessage={
                touched.fundName && errors.fundName ? errors.fundName : ""
              }
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setFieldValue("fundName", e.target.value)}
            />

            <MoneyInput
              heading="Estimated total value"
              description="How much you think your fund investment is worth"
              value={values.totalValue?.toString() || ""}
              currency={currencySymbol}
              onChange={(value) => setFieldValue("totalValue", Number(value))}
              errorMessage={
                touched.totalValue && errors.totalValue ? errors.totalValue : ""
              }
            />

            <ButtonsContainer>
              <FormButton
                onClick={submitForm}
                type="submit"
                text="Save & Continue"
                disclaimerIcon={<LockIcon />}
                disclaimerText={
                  <div>
                    We take your{" "}
                    <a
                      href="https://www.islamicfinanceguru.com/security-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy seriously
                    </a>
                    . Our institutional-grade security ensures your data is
                    confidential.
                  </div>
                }
                leftArrowOnClick={isMobile ? previousPage : undefined}
                leftArrowLabel={isMobile ? "Back" : undefined}
              />
            </ButtonsContainer>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default FundForm;

import { gql } from "@apollo/client";
import { logException } from "@helpers/logException";
import { ShareTickerInterface } from "@ifgengineering/hip-app-domain/src";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_SHARE_API,
  DELETE_SHARE_API,
  UPDATE_SHARE_API,
} from "../constants/actionTypes";
import { ShareItemObject, ShareObject } from "../constants/types";

export const createShareAPI = createAsyncThunk<
  ShareObject,
  {
    email: string;
    data: ShareItemObject[];
  }
>(CREATE_SHARE_API, async ({ email, data }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: [CreateShareItemInput!]!, $email: String!) {
          createShareItems(data: $data, email: $email) {
            id
            totalValue
            shareList {
              id
              currency
              totalValue
              shareName
              numShares
              market
              nameTicker
            }
          }
        }
      `,
      variables: {
        email,
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.createShareItems;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createShareAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateShareItemAPI = createAsyncThunk<
  ShareItemObject,
  ShareTickerInterface
>(UPDATE_SHARE_API, async (data) => {
  try {
    const response = await client.mutate({
      mutation: gql`
        mutation ($data: UpdateShareItem!) {
          updateShareItem(data: $data) {
            id
            currency
            totalValue
            shareName
            numShares
            market
            nameTicker
          }
        }
      `,
      variables: {
        data,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return [];
    }

    return response.data.updateShareItem;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      tag: "updateShareItemAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const deleteShareAPI = createAsyncThunk<string, number>(
  DELETE_SHARE_API,
  async (id) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation ($id: Float!) {
            deleteShareItem(id: $id) {
              message
            }
          }
        `,
        variables: {
          id,
        },
      });

      if (response.errors) {
        toast.warn("Something went wrong. Try again or contact support");
        return [];
      }

      return response.data.deleteShareItem;
    } catch (e: any) {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        tag: "deleteShareAPI",
        exception: e,
      });

      throw Error(e);
    }
  }
);

import React, { useContext } from "react";
import { Formik } from "formik";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import Input from "../../../../legacyComponents/Form/input/Input/Input.component";
import MoneyInput from "../../../../legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "../../../../legacyComponents/FormButton/FormButton.component";
import { FormikForm } from "../../../../legacyComponents/Form/input/styled/Form";
import { MEDIA_QUERIES } from "../../../mediaQueries";
import { ShareSchema } from "../../constants/validation";
import { ButtonsContainer } from "../../styled";
import { ShareTickerInterface } from "@ifgengineering/hip-app-domain/src";
import { createShareAPI, updateShareItemAPI } from "../../actions/share";
import { updateShareItemStore, updateShareStore } from "../../actions";
import { AuthContext } from "../..";
import { CurrencyContext } from "@components/CurrencyContext";

type Props = {
  data: ShareTickerInterface;
  previousPage: () => void;
  onSubmit: () => void;
};

const ShareForm = ({ data, previousPage, onSubmit }: Props): JSX.Element => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const email = useContext(AuthContext);
  const { currencySymbol } = useContext(CurrencyContext);
  const dispatch = useDispatch();

  const createAPI = async (values: ShareTickerInterface) => {
    const toSubmit = {
      ...values,
      currency: "gbp",
    };

    const share = await dispatch(
      createShareAPI({
        email,
        data: [toSubmit],
      })

      /**
        We need to type the dispatch to prevent type issues when using unwrap.
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();

    dispatch(updateShareStore(share));
  };

  const updateAPI = async (values: ShareTickerInterface) => {
    const childTrust = await dispatch(
      updateShareItemAPI(values)
      /**
        We need to type the dispatch to prevent type issues when using unwrap.
        But we can't do this from this component as it is sitting on the global level
        and the types are coming from each app store.
       @ts-ignore */
    ).unwrap();
    dispatch(updateShareItemStore(childTrust));
  };

  return (
    <Formik
      validationSchema={ShareSchema}
      initialValues={data}
      onSubmit={(share) => {
        if (share.id) {
          updateAPI(share);
        } else {
          createAPI(share);
        }

        onSubmit();
      }}
    >
      {({ values, setFieldValue, submitForm, errors, touched }) => {
        return (
          <>
            <FormikForm>
              <>
                <Input
                  value={values.shareName || ""}
                  type="text"
                  name="shareName"
                  heading="Name of Share"
                  description="The name of the share you own e.g. Apple."
                  errorMessage={
                    touched.shareName && errors.shareName
                      ? errors.shareName
                      : ""
                  }
                  onChange={(e) => setFieldValue("shareName", e.target.value)}
                />

                <MoneyInput
                  heading="Estimated Total Value of Shares"
                  description="How much you think your shares are worth"
                  value={values.totalValue?.toString() || ""}
                  currency={currencySymbol}
                  onChange={(value) =>
                    setFieldValue("totalValue", Number(value))
                  }
                  errorMessage={
                    touched.totalValue && errors.totalValue
                      ? errors.totalValue
                      : ""
                  }
                />

                <ButtonsContainer>
                  <FormButton
                    onClick={submitForm}
                    type="submit"
                    text="Save & Continue"
                    leftArrowOnClick={isMobile ? previousPage : undefined}
                    leftArrowLabel={isMobile ? "Back" : undefined}
                  />
                </ButtonsContainer>
              </>
            </FormikForm>
          </>
        );
      }}
    </Formik>
  );
};

export default ShareForm;

import { gql } from "@apollo/client";
import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain/src";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import {
  CREATE_PORTFOLIO_API,
  FETCH_PORTFOLIO_API,
} from "../constants/actionTypes";
import { logoutAction } from "@components/Login/actions";
import { logException } from "@helpers/logException";

const PORTFOLIO_DATA_GRAPHQL = `
{
  id
  estimatedEstateValue
  currency
  cash {
    id
    cashInHand
    cashISA
    totalCashValue
  }
  bankAccounts {
    id
    totalValue
    bankAccountList {
      id
      nameOnCard
      sortCode
      accountNumber
      bankAccountName
      cashInAccount
    }
  }
  commodities {
    id
    totalValue
    commoditiesList {
      id
      name
      commodityType
      description
      monetaryValue
      carat
      weight
    }
  }
  cryptoAccounts {
    id
    cryptoAccountList {
      id
      brokerName
      message
      description
    }
  }
  stockAccounts {
    id
    stockAccountList {
      id
      brokerName
      message
    }
  }
  debts {
    id
    totalValue
    debt {
      id
      type
      value
      description
    }
  }
  childTrustFunds {
    id
    totalValue
    childTrustFundList {
      id
      totalValue
      nameOfChild
      description
    }
  }
  funds {
    id
    totalValue
    fundsList {
      id
      totalValue
      fundName
      fundTicker
      numUnits
    }
  }
  stocks {
    id
    totalValue
    shareList {
      id
      currency
      totalValue
      shareName
      numShares
      market
      nameTicker
    }
  }
  crypto {
    id
    totalValue
    cryptoList {
      id
      coinName
      totalValue
      coinID
      numCoins
    }
  }
  startups {
    id
    totalValue
    startupInvestments {
      id
      companyName
      value
      numShares
      companyID
      description
    }
  }
  pensions {
    id
    totalValue
    pensionList {
      id
      description
      employerName
      pensionID
      pensionType
      totalValue
      pensionProviderName
      pensionName
    }
  }
  properties {
    id
    totalValue
    propertyList {
      id
      name
      description
      postcode
      bedroom
      value
    }
  }
  businessAssets {
    id
    totalValue
    businessList {
      id
      businessName
      totalValue
    }
  }
  owedAssets {
    id
    totalValue
    owedAssetList {
      id
      nameOfItem
      nameOfEntityLentTo
      description
      value
    }
  }
  agricultureAssets {
    id
    totalValue
    agricultureList {
      id
      name
      description
      value
    }
  }
  alternativeInvestments {
    id
    totalValue
    alternativeAssetList {
      id
      name
      description
      value
    }
  }
}
`;

type fetchPortfolioAPIReturn = {
  errors?: Array<{
    message: string;
    name: string;
    statusCode: number;
  }>;
  data?: PortfolioDataInterface;
};

export const fetchPortfolioAPI = createAsyncThunk<
  fetchPortfolioAPIReturn,
  string
>(FETCH_PORTFOLIO_API, async (email, { dispatch }) => {
  try {
    const response = await client.query({
      query: gql`
          query($email: String!, $portfolioData: CreatePortfolioDataInput!) {
            findPortfolioOrCreate(email: $email, portfolioData: $portfolioData) ${PORTFOLIO_DATA_GRAPHQL}
          }
        `,
      variables: {
        email,
        portfolioData: { currency: "gbp" },
      },
      fetchPolicy: "no-cache",
    });

    const {
      errors,
      data: { findPortfolioOrCreate: data },
    } = response;
    return { errors, data };
  } catch (e: any) {
    if (
      e.networkError.result.errors.some(({ statusCode }) => statusCode === 401)
    ) {
      dispatch(logoutAction());
    } else {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        email,
        tag: "fetchPortfolioAPI",
        exception: e,
      });
    }

    return { errors: e.networkError.result.errors };
  }
});

export const createPortfolioAPI = createAsyncThunk<
  PortfolioDataInterface,
  string
>(CREATE_PORTFOLIO_API, async (email, { dispatch }) => {
  try {
    const response = await client.mutate({
      mutation: gql`
          mutation($email: String!, $portfolioData: CreatePortfolioDataInput!) {
            createPortfolio(email: $email, portfolioData: $portfolioData) ${PORTFOLIO_DATA_GRAPHQL}
          }
        `,
      variables: {
        email,
        portfolioData: { currency: "gbp" },
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
    }

    return response.data.createPortfolio;
  } catch (e: any) {
    if (
      e.networkError.result.errors.some(({ statusCode }) => statusCode === 401)
    ) {
      dispatch(logoutAction());
    } else {
      toast.warn("Something went wrong. Try again or contact support");

      logException({
        email,
        tag: "createPortfolioAPI",
        exception: e,
      });
    }

    throw Error(e);
  }
});

import { PortfolioDataInterface } from "@ifgengineering/hip-app-domain/src";
import { EmptyObject } from "@reduxjs/toolkit";

export type Selector<T> = (
  state: EmptyObject & {
    portfolioData: PortfolioDataInterface;
  }
) => T;

export type PageType = {
  nextPage: () => void;
  previousPage: () => void;
  showTooltip?: boolean;
};

export enum ASSETS {
  cash = "cash",
  bankAccounts = "bankAccounts",
  debts = "debts",
  commodities = "commodities",
  childTrustFunds = "childTrustFunds",
  stocks = "stocks",
  crypto = "crypto",
  pensions = "pensions",
  properties = "properties",
  businessAssets = "businessAssets",
  startups = "startups",
  agricultureAssets = "agricultureAssets",
  alternativeInvestments = "alternativeInvestments",
  owedAssets = "owedAssets",
}

export type PortfolioList = Pick<
  PortfolioDataInterface,
  | "agricultureAssets"
  | "alternativeInvestments"
  | "bankAccounts"
  | "businessAssets"
  | "childTrustFunds"
  | "commodities"
  | "crypto"
  | "cryptoAccounts"
  | "debts"
  | "funds"
  | "owedAssets"
  | "pensions"
  | "properties"
  | "startups"
  | "stockAccounts"
  | "stocks"
>;

export const ASSETS_LIST_VALUES: Record<keyof PortfolioList, string> = {
  agricultureAssets: "agricultureList",
  alternativeInvestments: "alternativeAssetList",
  bankAccounts: "bankAccountList",
  businessAssets: "businessList",
  childTrustFunds: "childTrustFundList",
  commodities: "commoditiesList",
  crypto: "cryptoList",
  cryptoAccounts: "cryptoAccountList",
  debts: "debt",
  funds: "fundsList",
  owedAssets: "owedAssetList",
  pensions: "pensionList",
  properties: "propertyList",
  startups: "startupInvestments",
  stockAccounts: "stockAccountList",
  stocks: "shareList",
};
